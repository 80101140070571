import { setInstanceGetter } from '@wix/wix-vod-shared/dist/src/common/utils/get-auth-header';
import { createClient as createGqlClient } from '@wix/wix-vod-gql-api/dist/src/client/client';
import { PUBLIC_API_URL } from '@wix/wix-vod-gql-api/dist/src/constants';
export var configureInstance = function (_a) {
    var instance = _a.instance;
    setInstanceGetter(function () { return instance; });
};
export var createClient = function (baseUrl) {
    var href = new URL(PUBLIC_API_URL, baseUrl).href;
    var client = createGqlClient(href);
    var setInstance = function (instance) {
        client.setHeader('Authorization', instance);
        setInstanceGetter(function () { return instance; });
    };
    return { client: client, setInstance: setInstance };
};
